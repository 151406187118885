import ArticlePageTemplate from '../../components/templates/ArticlePage';
import Meta from '../../components/Meta.js';
import PropTypes from 'prop-types';
import React from 'react';
import withRoot from '../../hoc/withRoot';
import { BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';
import { MarkdownHtml } from '../../components/organisms/Markdown';
import { getProps as footerProps } from '../utils/mapper/footer';
import { graphql } from 'gatsby';
import { mapToProps as mapToIntroProps } from '../utils/mapper/intro';
import { mapToProps as mapToMarkdownProps } from '../utils/mapper/markdown';
import { mapToProps as mapToMetaProps } from '../utils/mapper/meta';
import { mapToProps as mapToTopbarProps } from '../utils/mapper/topbar';
import { siteMetadata } from '../utils/helper/data';

const ArticlePage = ({ location, data }) => {
  const smData = siteMetadata(data);

  return (
    <>
      <Meta {...mapToMetaProps(data, location)} />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            position: 1,
            name: smData.title,
            item: smData.siteUrl
          }
        ]}
      />
      <ArticlePageTemplate
        markdownComponent={MarkdownHtml}
        TopBarProps={mapToTopbarProps(data, location)}
        IntroProps={mapToIntroProps(data)}
        MarkdownProps={mapToMarkdownProps(data)}
        FooterProps={footerProps()}
      />
    </>
  );
};

ArticlePage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

export default withRoot(ArticlePage);

export const pageQuery = graphql`
  query ArticlePageTemplate($id: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        ...MetaSiteMetadata
      }
    }

    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        ...Intro
        ...ContentTeasers
        ...MetaFrontMatter
      }
      ...MarkdownContent
    }
    ...TopBar
  }
`;
